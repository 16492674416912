import { Controller } from "@hotwired/stimulus";
import JustValidate from "just-validate";
import { i18n } from "../src/custom/Internationalization";

export default class extends Controller {
  computeFieldOpts(field) {
    const opts = {};
    opts.errorsContainer = field.dataset.formValidateErrorsContainer;
    opts.errorFieldCssClass = this.tryParseOpt(
      field.dataset.formValidateErrorFieldCssClass,
    );
    opts.errorLabelCssClass =
      this.tryParseOpt(field.dataset.formValidateErrorLabelCssClass) ||
      "tw-bg-red-500 tw-text-white tw-p-2 tw-mt-1 tw-rounded";
    return opts;
  }

  computeFieldRules(field) {
    const rules = [];

    // If the field is explicitly set to not validate, then skip
    if (field.dataset.formValidate && field.dataset.formValidate === "false") {
      return rules;
    }

    if (
      field.dataset.formValidateRequired &&
      field.dataset.formValidateRequired !== "false"
    ) {
      rules.push(this.requiredFieldRule(field));
    }
    if (field.dataset.formValidateGreaterThan) {
      rules.push(this.greaterThanFieldRule(field));
    }
    if (field.dataset.formValidateMinNumber) {
      rules.push(this.minNumberFieldRule(field));
    }
    if (field.dataset.formValidateMaxNumber) {
      rules.push(this.maxNumberFieldRule(field));
    }
    if (field.dataset.formValidateInteger) {
      rules.push(this.integerFieldRule(field));
    }
    if (field.dataset.formValidateNumber) {
      rules.push(this.numberFieldRule(field));
    }
    if (field.dataset.formValidateEmail) {
      rules.push(this.emailFieldRule(field));
    }
    if (field.dataset.formValidateMultipleChoice) {
      rules.push(this.multipleChoiceFieldRule(field));
    }

    return rules.filter((rule) => rule !== null);
  }

  computeRequiredSuccessValues(field) {
    const successValues = [];
    const values =
      field.dataset.formValidateRequiredConditionalSuccessValues?.split(",");
    // Allow string true/false to work as a string or a boolean
    values.forEach((value) => {
      if (value === "true") {
        successValues.push(true);
      } else if (value === "false") {
        successValues.push(false);
      }
      successValues.push(value);
    });
    return successValues;
  }

  requiredFieldRule(field) {
    const rule = {
      rule: "required",
      errorMessage:
        field.dataset.formValidateRequiredMessage ||
        i18n.t("errors.generic_required"),
      errorLabelCssClass: field.dataset.formValidateErrorLabelCssClass,
    };

    if (field.dataset.formValidateRequiredConditional) {
      // Field is conditional, check if its already set in a good state
      // If so, then do not return a validator
      const successValues = this.computeRequiredSuccessValues(field);
      const conditionalFields = this.element.querySelectorAll(
        field.dataset.formValidateRequiredConditional,
      );

      const fieldValues = Array.from(conditionalFields).map((item) =>
        this.getTrueConditionalFieldValue(item),
      );

      // At least one of the conditional fields has a value that makes this not required (success)
      if (successValues.some((item) => fieldValues.includes(item))) {
        return null;
      }
    }
    return rule;
  }

  multipleChoiceFieldRule(field) {
    return {
      validator: () => () =>
        new Promise((resolve) => {
          // This implies that one of the options has already been validated so we can skip
          if (field.dataset.formValidateMultipleChoiceValid) {
            resolve(true);
          }

          const collection = this.element.querySelectorAll(
            `[data-form-validate-multiple-choice='${field.dataset.formValidateMultipleChoice}']`,
          );

          const selected = Array.from(collection).filter(
            (item) => item.checked,
          );
          if (collection.length === 0 || selected.length > 0) {
            collection.forEach((item) => {
              item.dataset.formValidateMultipleChoiceValid = "true";
            });
            resolve(true);
          }

          collection.forEach((item) => {
            item.dataset.formValidateMultipleChoiceValid = "false";
          });

          resolve(false);
        }),
      errorMessage:
        field.dataset.formValidateMultipleChoiceMessage ||
        i18n.t("errors.generic_multiple_choice"),
    };
  }

  greaterThanFieldRule(field) {
    return {
      validator: (value) => () =>
        new Promise((resolve) => {
          if (value == null) {
            resolve(true);
          }

          const otherField = this.element.querySelector(
            field.dataset.formValidateGreaterThan,
          );
          if (otherField?.value == null) {
            resolve(true);
          }

          if (parseFloat(value) > parseFloat(otherField.value)) {
            resolve(true);
          }
          resolve(false);
        }),
      errorMessage:
        field.dataset.formValidateGreaterThanMessage ||
        i18n.t("errors.generic_greater_than"),
    };
  }

  minNumberFieldRule(field) {
    return {
      rule: "minNumber",
      value: parseFloat(field.dataset.formValidateMinNumber, 10),
      errorMessage: field.dataset.formValidateMinNumberMessage,
    };
  }

  maxNumberFieldRule(field) {
    return {
      rule: "maxNumber",
      value: parseFloat(field.dataset.formValidateMaxNumber, 10),
      errorMessage: field.dataset.formValidateMaxNumberMessage,
    };
  }

  integerFieldRule(field) {
    return {
      rule: "integer",
      errorMessage: field.dataset.formValidateIntegerMessage,
    };
  }

  numberFieldRule(field) {
    return {
      rule: "number",
      errorMessage: field.dataset.formValidateNumberMessage,
    };
  }

  emailFieldRule(field) {
    return {
      rule: "email",
      errorMessage: field.dataset.formValidateEmailMessage,
    };
  }

  getTrueConditionalFieldValue(conditionalField) {
    let { value } = conditionalField;

    // Checkboxes always return their value to need to compare checked/unchecked instead
    if (conditionalField.type === "checkbox") {
      value = conditionalField.checked;
    }

    // Radio buttons should return their value only if selected
    if (conditionalField.type === "radio") {
      if (conditionalField.checked) {
        value = conditionalField.value;
      } else {
        value = null;
      }
    }

    return value;
  }

  bindConditionalRequiredChange(field) {
    if (!field.dataset.formValidateRequiredConditional) {
      return;
    }

    const conditionalFields = this.element.querySelectorAll(
      field.dataset.formValidateRequiredConditional,
    );

    // Don't rebind the change event every time the validations are re-calculated
    if (field.dataset.formValidateConditionalBound) {
      return;
    }

    field.dataset.formValidateConditionalBound = true;

    // There might be more than one, for example a radio button group
    conditionalFields.forEach((conditionalField) => {
      conditionalField.addEventListener("change", (ev) => {
        // Is the field now in a good state?
        // If so remove validations
        // TODO update this to re-add validations other than required
        const correspondingLabel = this.element.querySelector(
          `[for='${field.id}']`,
        );

        const successValues = this.computeRequiredSuccessValues(field);
        const conditionalValue = this.getTrueConditionalFieldValue(ev.target);

        if (successValues.includes(conditionalValue)) {
          this.validator.removeField(`#${field.id}`);
          if (correspondingLabel) {
            correspondingLabel.classList.remove("required");
          }
        } else {
          const rules = this.computeFieldRules(field);
          if (rules.length > 0) {
            this.validator.addField(
              `#${field.id}`,
              rules,
              this.computeFieldOpts(field),
            );
          }
          if (correspondingLabel) {
            correspondingLabel.classList.add("required");
          }
        }

        if (this.element.dataset.formValidateInvalid) {
          this.validator.revalidate();
        }
      });
    });
  }

  bindFields() {
    this.element.querySelectorAll("[data-form-validate]").forEach((field) => {
      this.bindConditionalRequiredChange(field);

      const rules = this.computeFieldRules(field);
      if (rules.length > 0) {
        this.validator.addField(
          `#${field.id}`,
          rules,
          this.computeFieldOpts(field),
        );
      }
    });
  }

  tryParseOpt(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  computeGlobalOpts() {
    const opts = {};
    opts.errorsContainer = this.element.dataset.formValidateErrorsContainer;
    opts.errorFieldCssClass = this.tryParseOpt(
      this.element.dataset.formValidateErrorFieldCssClass,
    );
    opts.lockForm = false;
    return opts;
  }

  configureValidator() {
    this.validator = new JustValidate(this.element, this.computeGlobalOpts())
      .onSuccess((ev) => {
        if (this.element.dataset.formValidateInvalid) {
          delete this.element.dataset.formValidateInvalid;
        }

        if (this.element.dataset.remote === "true") {
          ev?.preventDefault();
          this.element.dataset.formValidateValid = "true";
        } else {
          this.element.submit();
        }
      })
      .onFail((fields) => {
        document.dispatchEvent(
          new CustomEvent("form-validate:fail", { detail: fields }),
        );
        this.element.dataset.formValidateInvalid = "true";
      })
      .onValidate(() => {
        // Remove the valid state from all multiple choice fields so that the validation reruns
        this.element
          .querySelectorAll("[data-form-validate-multiple-choice-valid]")
          .forEach((field) => {
            delete field.dataset.formValidateMultipleChoiceValid;
          });
      });

    // RailsUJS causes the form to submit regardless of validation
    // so we'll intercept that event if the for hasn't successfully validated yet.
    // This isn't necessary for non-remote forms.
    if (this.element.dataset.remote === "true") {
      this.element.addEventListener("ajax:beforeSend", (event) => {
        if (!event.target.dataset.formValidateValid) {
          event.preventDefault();
          return false;
        }
        this.validator.destroy();
        return true;
      });
    }
  }

  setBound() {
    // This is running multiple times per form when the form is loaded via UJS
    // this attribute prevents that multiple binding
    this.element.dataset.formValidateBound = true;
  }

  rebind() {
    delete this.element.dataset.formValidateBound;
    delete this.element.dataset.formValidateInvalid;
    delete this.element.dataset.formValidateValid;
    this.validator.destroy();
    this.connect();
  }

  connect() {
    if (this.element.hasAttribute("data-form-validate-bound")) {
      return;
    }

    this.configureValidator();
    this.bindFields();
    this.setBound();
  }

  revalidate() {
    return this.validator.revalidate();
  }
}
