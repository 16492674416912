import { Controller } from "@hotwired/stimulus";
import { previewFilestackFile } from "../src/plugins/Filestack";

export default class extends Controller {
  static targets = ["preview", "filename", "remove", "download"];

  previewFile(event) {
    event.preventDefault();
    previewFilestackFile(event.currentTarget);
  }

  removeFile(event) {
    event.preventDefault();

    if (event.currentTarget.dataset.handle == null) {
      window.Rollbar?.error("A file handle must be provided to remove a file");
      return;
    }

    this.dispatch("remove", {
      detail: { handle: event.currentTarget.dataset.handle },
    });
  }

  connect() {
    this.filenameTarget.innerHTML = this.element.dataset.filename;
    this.downloadTarget.href = this.element.dataset.href;
    this.previewTarget.href = this.element.dataset.href;
    this.removeTarget.dataset.handle = this.element.dataset.handle;
  }
}
